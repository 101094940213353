import { MessageKey, useTranslations } from "@properate/translations";
import { Divider, Select } from "antd";
import { ReactNode } from "react";
import { getUserNameWithEmail, IncidentClientSide } from "@properate/common";
import dayjs from "@properate/dayjs";
import { useUser } from "@properate/auth";
import { IncidentAlarmComponentWrapper } from "@/pages/alarmSystem/incident/IncidentAlarmComponentWrapper";
import { IncidentTimer } from "./IncidentTimer";
import { IncidentActivity } from "./IncidentActivity";

type Props = {
  assignButtons: ReactNode[];
  data: IncidentClientSide;
  name: string;
  onChangeStatus: (status: IncidentClientSide["status"]) => void;
  status: IncidentClientSide["status"] | undefined;
  refetchIncident: () => void;
};

export const IncidentInfo = ({
  assignButtons,
  data,
  name,
  onChangeStatus,
  status,
  refetchIncident,
}: Props) => {
  const t = useTranslations();
  const currentUser = useUser();

  const isAssigned = !!data.assigned?.user;
  const isAssignedToMe = data.assigned?.user.email === currentUser.email;
  const canChangeStatus = data.status !== "resolved" && isAssignedToMe;

  const statusSelectOptions = [
    {
      label: t("incident.statuses.unresolved"),
      value: "unresolved",
    },
    {
      label: t("incident.statuses.in_progress"),
      value: "in_progress",
    },
    {
      label: t("incident.statuses.resolved"),
      value: "resolved",
    },
  ];

  return (
    <div className="h-full flex flex-col">
      <h2 className="mb-0">{t("incident.incident-information")}</h2>
      <Divider className="my-4" />
      {!isAssigned && (
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">{assignButtons}</div>
          {data.alert_next_at && (
            <IncidentTimer
              refetchIncident={refetchIncident}
              sendToNextAt={data.alert_next_at}
            />
          )}
          <div className="flex gap-2">
            <div>{t("incident.responsible")}:</div>
            <div className="font-bold">
              {getUserNameWithEmail(data.responsible?.user) ||
                (data.notified_people?.user.length
                  ? data.notified_people?.user
                      .map((user) => getUserNameWithEmail(user))
                      .join(", ")
                  : t("incident.no-responsible-people"))}
            </div>
          </div>
        </div>
      )}
      {isAssigned && (
        <div className="flex gap-2">
          <div>{t("incident.assigned-to")}:</div>
          <div className="font-bold">
            {getUserNameWithEmail(data.assigned?.user)}
          </div>
        </div>
      )}
      <div className="my-6 flex flex-col gap-2">
        <div className="flex gap-2">
          <div>{t("incident.name")}:</div>
          <div className="font-bold">{name}</div>
        </div>
        <div className="flex gap-2">
          <div>{t("incident.reported-at")}:</div>
          <div>{dayjs(data.start).format("DD/MM YYYY HH:mm")}</div>
        </div>
        <div className="flex gap-2">
          <div>{t("incident.status")}:</div>
          {canChangeStatus ? (
            <Select
              className="w-40"
              onSelect={onChangeStatus}
              value={status}
              options={statusSelectOptions}
            />
          ) : (
            t(`incident.statuses.${data.status}` as MessageKey)
          )}
        </div>
      </div>
      <IncidentAlarmComponentWrapper>
        <IncidentActivity
          alarmEventIds={data.alarm_event_id_list}
          incident={data}
        />
      </IncidentAlarmComponentWrapper>
    </div>
  );
};

import { ReactNode, useEffect, useMemo, useState } from "react";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import Icon, {
  AppstoreAddOutlined,
  FileSearchOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Step } from "react-joyride";
import { useUser } from "@properate/auth";
import { useTranslations, MessageKey } from "@properate/translations";
import {
  getEnergyCompareLink,
  getEnergyFlowLink,
  getEnergyLink,
  getEnergyStateFromUrl,
  State,
} from "@/layout/utils";
import { useOnboarding } from "@/utils/onboarding";
import { FeatureFlag } from "@/services/featureAccess/types";
import useUserFeatureAccess from "@/services/featureAccess/useUserFeatureAccess";
import { useBuildingModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";
import { AccessModules } from "@/features/organizationAdmin";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { JoyrideWrapper } from "../components/JoyrideWrapper/JoyrideWrapper";
import { ReactComponent as AnalysisSvg } from "./icons/analysis.svg";
import { ReactComponent as WasteSvg } from "./icons/waste.svg";
import { ReactComponent as EnergySvg } from "./icons/bolt.svg";
import { ReactComponent as DashboardSvg } from "./icons/dashboard.svg";
import { ReactComponent as ThirdPartySvg } from "./icons/desktop_mac.svg";
import { ReactComponent as TechnicalSchemaSvg } from "./icons/hvac.svg";
import { ReactComponent as FloorPlanSvg } from "./icons/meeting_room.svg";
import { ReactComponent as AlarmsSvg } from "./icons/notification_important.svg";
import { ReactComponent as WorkOrderSvg } from "./icons/playlist_add_check.svg";
import { ReactComponent as IndoorClimateSvg } from "./icons/thermostat.svg";
import { ReactComponent as VirtualSensorSvg } from "./icons/virtual_sensor.svg";
import { ReactComponent as AnomalySvg } from "./icons/anomaly.svg";
import { ReactComponent as CloudAutomationSvg } from "./icons/cloud_automation.svg";
import { ReactComponent as FilesSvg } from "./icons/topic.svg";
import { ReactComponent as SetPointsSvg } from "./icons/tune.svg";
import { ReactComponent as Calendar } from "./icons/calendar.svg";
import { StyledLink } from "./elements";

interface BuildingMenuItem {
  icon?: any;
  children?: Array<Omit<BuildingMenuItem, "children">>;
  label: ReactNode;
  key: string;
  featureFlagForAccess?: FeatureFlag;
  isHidden?: boolean;
}

type BuildingMenu = Record<string, BuildingMenuItem>;

const STEPS: (Step & { content: MessageKey })[] = [
  {
    placement: "top",
    target: ".ant-menu-submenu.ant-menu-submenu-inline .ant-menu-title-content",
    content: "common.sidebar.joyride-step",
  },
];
export const useBuildingMenu = (state: State | null): BuildingMenu => {
  const t = useTranslations();

  const energyLink = getEnergyLink(state);
  const energyCompareLink = getEnergyCompareLink(state);
  const energyFlowLink = getEnergyFlowLink(state);
  const { accessModules } = useBuildingModulesAccess();
  return useMemo(
    () => ({
      building: {
        label: (
          <StyledLink to="building">{t("common.sidebar.overview")}</StyledLink>
        ),
        key: "building",
        icon: <Icon component={DashboardSvg} />,
      },
      vnc: {
        label: <StyledLink to="vnc">{t("common.sidebar.vnc")}</StyledLink>,
        key: "vnc",
        icon: <Icon component={ThirdPartySvg} />,
      },
      files: {
        label: <StyledLink to="files">{t("common.sidebar.files")}</StyledLink>,
        key: "files",
        icon: <Icon component={FilesSvg} />,
        isHidden: !accessModules.includes(AccessModules.files),
      },
      threeD: {
        label: <StyledLink to="threeD">{t("common.sidebar.3d")}</StyledLink>,
        key: "threeD",
      },
      energy: {
        label: t("common.sidebar.energy"),
        key: "energyMenu",
        icon: <Icon component={EnergySvg} />,
        featureFlagForAccess: "canAccessEnergyPage",
        isHidden: !accessModules.includes(AccessModules.energy),
        children: [
          {
            label: (
              <StyledLink to={energyLink} state={state}>
                ■ {t("common.sidebar.energy-consumption")}
              </StyledLink>
            ),
            key: "energyConsumption",
          },
          {
            label: (
              <StyledLink to={energyCompareLink} state={state}>
                ■ {t("common.sidebar.energy-comparison")}
              </StyledLink>
            ),
            key: "energyCompare",
          },
          {
            label: (
              <StyledLink to={energyFlowLink} state={state}>
                ■ {t("common.sidebar.energy-flow")}
              </StyledLink>
            ),
            key: "energyFlow",
          },
        ],
      },
      settings: {
        label: (
          <StyledLink to="settings">{t("common.sidebar.settings")}</StyledLink>
        ),
        key: "settings",
      },
      sd: {
        label: (
          <StyledLink to="sd">{t("common.sidebar.third-party-app")}</StyledLink>
        ),
        key: "sd",
        icon: <Icon component={ThirdPartySvg} />,
        featureFlagForAccess: "canAccessSDPage",
        isHidden: !accessModules.includes(AccessModules.thirdParty),
      },
      timeseries: {
        label: (
          <StyledLink to="timeseries">
            {t("common.sidebar.timeseries")}
          </StyledLink>
        ),
        key: "timeseries",
        icon: <FileSearchOutlined />,
        isHidden: !accessModules.includes(AccessModules.timeseries),
      },
      components: {
        label: (
          <StyledLink to="components">
            {t("common.sidebar.components")}
          </StyledLink>
        ),
        key: "components",
        icon: <AppstoreAddOutlined />,
        isHidden: !accessModules.includes(AccessModules.components),
      },
      anomaly: {
        label: (
          <StyledLink to="anomaly">{t("common.sidebar.anomaly")}</StyledLink>
        ),
        key: "anomaly",
        icon: <Icon component={AnomalySvg} />,
        featureFlagForAccess: "canAccessAnomalyPage",
      },
      virtualSensor: {
        label: (
          <StyledLink to="virtualSensor">
            {t("common.sidebar.virtual-sensors")}
          </StyledLink>
        ),
        key: "virtualSensor",
        icon: <Icon component={VirtualSensorSvg} />,
        isHidden: !accessModules.includes(AccessModules.virtualSensors),
      },
      cloudAutomation: {
        label: (
          <StyledLink to="cloudAutomation">
            {t("common.sidebar.cloud-automation")}
          </StyledLink>
        ),
        key: "cloudAutomation",
        icon: <Icon component={CloudAutomationSvg} />,
        isHidden: !accessModules.includes(AccessModules.cloudAutomation),
      },
      floorPlan: {
        label: (
          <StyledLink to="floorPlan">
            {t("common.sidebar.floor-plan")}
          </StyledLink>
        ),
        key: "floorPlan",
        icon: <Icon component={FloorPlanSvg} />,
        featureFlagForAccess: "canAccessFloorPlanPage",
        isHidden: !accessModules.includes(AccessModules.roomManagement),
      },
      floorPlanV2: {
        label: (
          <StyledLink to="floor-plan">
            {t("common.sidebar.floor-plan-v2")}
          </StyledLink>
        ),
        key: "floor-plan",
        icon: <Icon component={FloorPlanSvg} />,
        featureFlagForAccess: "canAccessFloorPlanPage",
        isHidden: !accessModules.includes(AccessModules.roomManagementV2),
      },
      indoorClimate: {
        label: (
          <StyledLink to="indoorClimate">
            {t("common.sidebar.inner-climate")}
          </StyledLink>
        ),
        key: "indoorClimate",
        icon: <Icon component={IndoorClimateSvg} />,
        featureFlagForAccess: "canAccessIndoorClimatePage",
        isHidden: !accessModules.includes(AccessModules.indoorClimate),
      },
      systemSchema: {
        label: (
          <StyledLink to="systemSchema">
            {t("common.sidebar.technical-schema")}
          </StyledLink>
        ),
        key: "systemSchema",
        icon: <Icon component={TechnicalSchemaSvg} />,
      },
      technicalSchema: {
        label: (
          <StyledLink to="technicalSchema">
            {t("common.sidebar.technical-schema")}
          </StyledLink>
        ),
        key: "technicalSchema",
        icon: <Icon component={TechnicalSchemaSvg} />,
        isHidden: !accessModules.includes(AccessModules.technicalSchema),
      },
      waste: {
        label: <StyledLink to="waste">{t("common.sidebar.waste")}</StyledLink>,
        key: "waste",
        icon: <Icon component={WasteSvg} />,
        featureFlagForAccess: "canAccessWastePage",
        isHidden: !accessModules.includes(AccessModules.waste),
      },
      alarms: {
        label: (
          <StyledLink to="alarms">{t("common.sidebar.alarms")}</StyledLink>
        ),
        key: "alarms",
        icon: <Icon component={AlarmsSvg} />,
        isHidden: !accessModules.includes(AccessModules.alarms),
      },
      calendar: {
        label: (
          <StyledLink to="calendar">{t("common.sidebar.calendar")}</StyledLink>
        ),
        key: "calendar",
        icon: <Icon component={Calendar} />,
        isHidden: !accessModules.includes(AccessModules.calendar),
      },
      setPoints: {
        label: (
          <StyledLink to="writable">{t("common.sidebar.setpoints")}</StyledLink>
        ),
        key: "writable",
        icon: <Icon component={SetPointsSvg} />,
        featureFlagForAccess: "canAccessSetPointsPage",
        isHidden: !accessModules.includes(AccessModules.setPoints),
      },
      reports: {
        label: (
          <StyledLink to="reports">{t("common.sidebar.reports")}</StyledLink>
        ),
        key: "reports",
        icon: <FileTextOutlined />,
        featureFlagForAccess: "canAccessReportsPage",
        isHidden: ![
          AccessModules.energy,
          AccessModules.indoorClimate,
          AccessModules.waste,
        ].some((item) => accessModules.includes(item)),
      },
      workOrders: {
        label: (
          <StyledLink to="workOrders">
            {t("common.sidebar.task-manager")}
          </StyledLink>
        ),
        key: "workOrders",
        icon: <Icon component={WorkOrderSvg} />,
        isHidden: !accessModules.includes(AccessModules.taskManagement),
      },
      analysis: {
        label: t("common.sidebar.analysis"),
        key: "analyse",
        icon: <Icon component={AnalysisSvg} />,
        isHidden: !accessModules.includes(AccessModules.analysis),
        children: [
          {
            key: "simple",
            label: (
              <StyledLink to="analysis/simple">
                ■ {t("common.sidebar.timeseries-graph")}
              </StyledLink>
            ),
          },
          {
            key: "scatterplot",
            label: (
              <StyledLink to="analysis/scatterplot">
                ■ {t("common.sidebar.xy-graph")}
              </StyledLink>
            ),
          },
          {
            key: "gauge",
            label: (
              <StyledLink to="analysis/gauge">
                ■ {t("common.sidebar.gauge")}
              </StyledLink>
            ),
          },
          {
            key: "heat-map",
            label: (
              <StyledLink to="analysis/heat-map">
                ■ {t("common.sidebar.heatmap")}
              </StyledLink>
            ),
          },
        ],
      },
      alertsSystem: {
        label: t("common.sidebar.alarms-system"),
        key: "alarmMenu",
        icon: <Icon component={AlarmsSvg} />,
        featureFlagForAccess: "canAccessAlarmsMenu",
        isHidden: !accessModules.includes(AccessModules.alarms),
        children: [
          {
            label: (
              <StyledLink to="incidents">
                ■ {t("common.sidebar.incident-handling")}
              </StyledLink>
            ),
            key: "incidents",
          },
          {
            label: (
              <StyledLink to="newAlarms">
                ■ {t("common.sidebar.alarms-configuration")}
              </StyledLink>
            ),
            key: "newAlarms",
          },
          {
            label: (
              <StyledLink to="alertConfiguration">
                ■ {t("common.sidebar.alerts-configuration")}
              </StyledLink>
            ),
            key: "alertConfiguration",
          },
          {
            label: (
              <StyledLink to="alertGroups">
                ■ {t("common.sidebar.groups")}
              </StyledLink>
            ),
            key: "alertGroups",
          },
        ],
      },
    }),
    [accessModules, energyCompareLink, energyFlowLink, energyLink, state, t],
  );
};

function getSelectedKeys(buildingMenu: BuildingMenu, pathname: string) {
  const possibleKeys = Object.values(buildingMenu).flatMap(
    (buildingMenuItem) => {
      if (buildingMenuItem.children) {
        return buildingMenuItem.children
          .map((child) => child.key)
          .concat(buildingMenuItem.key);
      }
      return buildingMenuItem.key;
    },
  );
  return possibleKeys.filter((possibleKey) => pathname.includes(possibleKey));
}

export const BuildingSideMenu = (props: { inlineCollapsed: boolean }) => {
  const t = useTranslations();

  const user = useUser();
  const featureFlags = useUserFeatureAccess();
  const [onboardingSteps, setOnboardingStepCompleted] = useOnboarding(
    STEPS.map((step) => ({ ...step, content: t(step.content) })),
  );
  const { pathname, search } = useLocation();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const navigate = useNavigate();
  const state = getEnergyStateFromUrl(pathname, search);
  const { isLoaded } = useBuildingModulesAccess();

  const buildingMenu = useBuildingMenu(state);
  const selectedKeys = getSelectedKeys(buildingMenu, pathname);

  const menu = [
    buildingMenu.building,
    buildingMenu.alarms,
    buildingMenu.alertsSystem,
    buildingMenu.energy,
    // show calendar in the side menu when it is tested well
    ...(user.isViewer ? [] : [buildingMenu.calendar]),
    buildingMenu.indoorClimate,
    buildingMenu.floorPlan,
    buildingMenu.floorPlanV2,
    buildingMenu.technicalSchema,
    ...(user.isViewer ? [] : [buildingMenu.setPoints]),
    buildingMenu.files,
    ...(user.isViewer ? [] : [buildingMenu.sd]),
    buildingMenu.reports,
    buildingMenu.workOrders,
    buildingMenu.timeseries,
    buildingMenu.components,
    buildingMenu.anomaly,
    buildingMenu.virtualSensor,
    buildingMenu.cloudAutomation,
    buildingMenu.analysis,
    buildingMenu.waste,
  ];

  function handleOpenChange(openKeys: string[]) {
    setOpenKeys(openKeys);
  }

  function handleClick({ key }: { key: string }) {
    if (
      !key.startsWith("energy") &&
      !["simple", "scatterplot", "gauge", "heat-map"].includes(key) &&
      !["newAlarms", "incidents", "alertGroups", "alertConfiguration"].includes(
        key,
      )
    ) {
      setOpenKeys([]);
    }
    if (["simple", "scatterplot", "gauge", "heat-map"].includes(key)) {
      navigate(`analysis/${key}`);
    } else {
      navigate(key);
    }
  }

  useEffect(() => {
    if (
      (selectedKeys.includes("energyConsumption") ||
        selectedKeys.includes("energyCompare") ||
        selectedKeys.includes("energyFlow")) &&
      !openKeys.includes("energyMenu")
    ) {
      setOpenKeys(["energyMenu"]);
    }
    if (
      (selectedKeys.includes("newAlarms") ||
        selectedKeys.includes("incidents") ||
        selectedKeys.includes("alertGroups") ||
        selectedKeys.includes("alertConfiguration")) &&
      !openKeys.includes("alarmMenu")
    ) {
      setOpenKeys(["alarmMenu"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Menu
        mode="inline"
        inlineCollapsed={props.inlineCollapsed}
        triggerSubMenuAction="click"
        onClick={handleClick}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        selectedKeys={selectedKeys}
        items={menu.map(
          ({ featureFlagForAccess, isHidden = false, ...item }) => {
            if (
              isHidden ||
              (featureFlagForAccess && !featureFlags[featureFlagForAccess])
            ) {
              return null;
            }
            return {
              ...item,
              children: item.children?.map((item) => item),
            };
          },
        )}
      />
      {!isLoaded && (
        <SpinnerWithDelay isLoading>
          <div />
        </SpinnerWithDelay>
      )}
      {onboardingSteps.length > 0 && (
        <JoyrideWrapper
          content={onboardingSteps[0]}
          disableScrolling
          onClose={setOnboardingStepCompleted}
        />
      )}
    </>
  );
};

import {
  Button,
  Form,
  FormInstance,
  FormListFieldData,
  Select,
  Switch,
  TimePicker,
} from "antd";
import { useTranslations } from "@properate/translations";
import { DeleteOutlined } from "@ant-design/icons";
import React, { useMemo, useState } from "react";
import {
  AlertDay,
  AlertRuleGroupRuleWithPeriod,
  allKeysForAlertDays,
} from "@properate/common";
import dayjs from "@properate/dayjs";
import {
  FormValues,
  TIMEOUT_SELECT_OPTIONS,
  useGroups,
} from "@/pages/alarmSystem/ruleDetails/utils";

type Props = {
  form: FormInstance<FormValues>;
  fields: FormListFieldData[];
  onAdd: (group: AlertRuleGroupRuleWithPeriod) => void;
  onRemove: (key: number) => void;
};

export function AlertRuleDetailsGroups(props: Props) {
  const t = useTranslations();
  const groups = useGroups();
  const rules = Form.useWatch("group_rules", props.form) ?? [];

  function handleAdd() {
    // pre-select the first group so we don't have to validate the entry
    const preSelectedGroup = groups.data?.[0];

    if (preSelectedGroup) {
      const newGroup: AlertRuleGroupRuleWithPeriod = {
        group_id: preSelectedGroup.id,
        period: {
          day: AlertDay.Monday,
          hourStart: null,
          hourEnd: null,
          minuteStart: null,
          minuteEnd: null,
        },
        notify_by_priority: {
          timeout: 15,
          notify_all: false,
        },
      };

      props.onAdd(newGroup);
    }
  }

  return (
    <div>
      {props.fields.map(
        (field) =>
          rules[field.key] && (
            <GroupItem
              key={field.key}
              form={props.form}
              field={field}
              group={rules[field.key]}
              groupIndex={field.key}
              onRemove={props.onRemove}
              onUpdateTime={(period) => {
                const values = props.form.getFieldsValue();

                props.form.setFieldsValue({
                  ...values,
                  // @ts-expect-error the AntD types are throwing errors here, but the values are being set correctly
                  group_rules: values.group_rules.map((group, index) => {
                    if (index === field.key) {
                      return {
                        ...group,
                        period: {
                          ...group.period,
                          ...period,
                        },
                      };
                    }

                    return group;
                  }),
                });
              }}
            />
          ),
      )}
      <Button
        type="primary"
        ghost
        onClick={handleAdd}
        className="float-right"
        loading={groups.isLoading}
      >
        + {t("alert-rule.add-group-rule")}
      </Button>
    </div>
  );
}

type GroupItemProps = {
  form: FormInstance<FormValues>;
  field: FormListFieldData;
  group: AlertRuleGroupRuleWithPeriod;
  groupIndex: number;
  onRemove: (key: number) => void;
  onUpdateTime: (period: {
    hourStart: number;
    hourEnd: number;
    minuteStart: number;
    minuteEnd: number;
  }) => void;
};

function GroupItem(props: GroupItemProps) {
  const t = useTranslations();
  const hasTime = props.group.period.hourStart !== null;
  const [showTimePicker, setShowTimePicker] = useState(hasTime);
  const groups = useGroups();
  const showTimeout = !props.group.notify_by_priority.notify_all;

  const timePickerValue = useMemo(():
    | [dayjs.Dayjs, dayjs.Dayjs]
    | undefined => {
    if (
      props.group.period.hourStart === null ||
      props.group.period.hourEnd === null
    ) {
      return undefined;
    }

    return [
      dayjs()
        .startOf("day")
        .set("hour", props.group.period.hourStart)
        .set("minute", props.group.period.minuteStart),
      dayjs()
        .startOf("day")
        .set("hour", props.group.period.hourEnd)
        .set("minute", props.group.period.minuteEnd),
    ];
  }, [
    props.group.period.hourStart,
    props.group.period.hourEnd,
    props.group.period.minuteStart,
    props.group.period.minuteEnd,
  ]);

  return (
    <div className="flex gap-2">
      <Form.Item name={[props.field.key, "period", "day"]} className="w-40">
        <Select placeholder={t("alert-rule.field-day-placeholder")}>
          {Object.values(AlertDay).map((day) => (
            <Select.Option key={day} value={day}>
              {t(allKeysForAlertDays[day])}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {showTimePicker ? (
        <Form.Item>
          <TimePicker.RangePicker
            format="HH:mm"
            onChange={([start, end]) => {
              if (!start || !end) {
                return console.warn(
                  "Skipping group time update. Start and end date are required.",
                  { start, end },
                );
              }

              props.onUpdateTime({
                hourStart: start.hours(),
                hourEnd: end.hours(),
                minuteStart: start.minutes(),
                minuteEnd: end.minutes(),
              });
            }}
            defaultValue={timePickerValue}
          />
        </Form.Item>
      ) : (
        <Button onClick={() => setShowTimePicker(true)}>
          {t("alert-rule.add-time")}
        </Button>
      )}
      <Form.Item name={[props.field.key, "group_id"]} className="w-48">
        <Select
          placeholder={t("alert-rule.field-group-placeholder")}
          loading={groups.isLoading}
          {...(groups.isLoading && { labelRender: () => "" })}
        >
          {groups.data?.map((group) => (
            <Select.Option key={group.id} value={group.id}>
              {group.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <div className="flex-1" />
      <Form.Item name={[props.field.key, "notify_by_priority", "notify_all"]}>
        <Switch
          checkedChildren={t("alert-rule.field-notify-by-priority-true")}
          unCheckedChildren={t("alert-rule.field-notify-by-priority-false")}
        />
      </Form.Item>
      {showTimeout && (
        <Form.Item
          name={[props.field.key, "notify_by_priority", "timeout"]}
          className="w-28"
          rules={[
            { required: true, message: t("alert-rule.timeout-required") },
          ]}
        >
          <Select>
            {TIMEOUT_SELECT_OPTIONS.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {t(option.translationKey)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Button
        type="text"
        icon={<DeleteOutlined />}
        onClick={() => props.onRemove(props.field.key)}
      />
    </div>
  );
}

import { useUser } from "@properate/auth";
import { FunctionComponent, useState } from "react";
import { Button, Switch, Tooltip, App } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  RightOutlined,
  CaretRightOutlined,
  SettingOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { ShowEdit } from "../../../components/ShowEdit";
import { CalculationFlow } from "../types";
import TimeSeriesGraphButton from "../nodes/components/TimeseriesGraphButton";
import { AuditLogButton } from "../nodes/components/AuditLogButton";
import { SettingsModal } from "./SettingsModal";
import { CopyButton } from "./CopyButton";
import { StatusBadge } from "./StatusBadge";

interface Props {
  calculationFlow: CalculationFlow;
  hasUnsavedChanges: boolean;
  onSave: () => void;
  onRun: () => void;
  onChange: (calculationFlow: CalculationFlow) => void;
}

const FlowPageHeader: FunctionComponent<Props> = ({
  calculationFlow,
  hasUnsavedChanges,
  onSave,
  onRun,
  onChange,
}) => {
  const { notification } = App.useApp();
  const currentBuildingId = useCurrentBuildingId();
  const [isSaving, setIsSaving] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const user = useUser();
  const t = useTranslations();

  const handleActiveChange = () => {
    onChange({
      ...calculationFlow,
      frequency: -calculationFlow.frequency,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    await onSave();
    setIsSaving(false);
    notification.success({
      message: t("calculation-flow.virtual-sensor-was-saved"),
    });
  };

  const handleSettingsModalOk = (calculationFlow: CalculationFlow) => {
    setIsSettingsModalVisible(false);
    onChange(calculationFlow);
  };

  return (
    <PageHeader
      title={
        <>
          <Link to={`/asset/${currentBuildingId}/${calculationFlow.type}`}>
            {calculationFlow.type === "virtualSensor"
              ? t("calculation-flow.virtual-sensors")
              : t("calculation-flow.cloud-automation")}
          </Link>
          <RightOutlined />
        </>
      }
      subTitle={
        user.isViewer ? (
          calculationFlow.name
        ) : (
          <ShowEdit
            text={calculationFlow.name}
            onFinish={async (name) => {
              onChange({ ...calculationFlow, name });
            }}
          />
        )
      }
      extra={
        <>
          {t("calculation-flow.status")}:
          <StatusBadge calculationFlow={calculationFlow} />
          {t("calculation-flow.active")}:
          <Switch
            onChange={handleActiveChange}
            checked={calculationFlow.frequency > 0}
            disabled={user.isViewer}
          />
          <Button
            icon={<SettingOutlined />}
            onClick={() => setIsSettingsModalVisible(true)}
            disabled={user.isViewer}
          />
          <AuditLogButton
            externalId={
              calculationFlow.health_ext_id
                ? { externalId: calculationFlow.health_ext_id }
                : null
            }
          />
          <TimeSeriesGraphButton
            externalId={
              calculationFlow.health_ext_id
                ? { externalId: calculationFlow.health_ext_id }
                : null
            }
            noExternalIdTooltip={""}
            icon={<HeartOutlined />}
          />
          <CopyButton calculationFlow={calculationFlow} />
          <Tooltip
            title={
              !hasUnsavedChanges
                ? t("calculation-flow.no-changes-to-save")
                : null
            }
          >
            <Button
              type="primary"
              onClick={handleSave}
              loading={isSaving}
              disabled={user.isViewer || !hasUnsavedChanges}
            >
              {t("calculation-flow.save")}
            </Button>
          </Tooltip>
          <Button
            type="primary"
            onClick={onRun}
            icon={<CaretRightOutlined />}
            disabled={user.isViewer}
          >
            {t("calculation-flow.calculate")}
          </Button>
          <ToggleSidebarButton
            hiddenWhenSidebarVisible
            sidebarHiddenContent={t("notes.show-notes-button")}
          />
          <SettingsModal
            open={isSettingsModalVisible}
            calculationFlow={calculationFlow}
            onCancel={() => setIsSettingsModalVisible(false)}
            onOk={handleSettingsModalOk}
          />
        </>
      }
    />
  );
};

export default FlowPageHeader;

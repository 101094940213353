import { CenteredSpinner } from "@properate/ui";
import { AutoComplete } from "antd";
import { useTranslations } from "@properate/translations";
import useSWR from "swr";
import { useState } from "react";
import * as React from "react";
import { getSearchableIncidentValues } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";
import { IncidentsLoader } from "@/features/alarms";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";

export const IncidentAutocompleteSearch = ({
  buildingId,
  defaultSearch,
  onSearchChange,
}: {
  buildingId?: number;
  defaultSearch?: IncidentsLoader["search"];
  onSearchChange: (search?: IncidentsLoader["search"]) => void;
}) => {
  const t = useTranslations();
  const handleAPIError = useHandleApiError();
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const renderLabel = (label: string) => {
    return (
      <ProperateHighlighter
        searchWords={searchValue?.split(" ") || [""]}
        autoEscape
        textToHighlight={label}
      />
    );
  };

  const {
    data: searchableValues,
    isLoading: isLoadingSearchableValues,
    error: searchValuesError,
  } = useSWR("searchableIncidentValues", () =>
    getSearchableIncidentValues(buildingId),
  );

  if (searchValuesError) {
    handleAPIError(searchValuesError);
  }

  return (
    <AutoComplete
      allowClear
      notFoundContent={
        isLoadingSearchableValues ? (
          <CenteredSpinner />
        ) : (
          t("incidents.no-matched-results")
        )
      }
      key="search"
      className="w-64"
      placeholder={t("incidents.search")}
      defaultValue={defaultSearch?.value}
      options={[
        ...(searchableValues?.timeseriesNameValues.map((name) => ({
          label: renderLabel(name),
          value: name,
          fieldName: "timeseries_name",
        })) || []),
        ...((!buildingId &&
          searchableValues?.buildingAddressValues.map((name) => ({
            label: renderLabel(name),
            value: name,
            fieldName: "building_address",
          }))) ||
          []),
        ...(searchableValues?.alarmValues.map(({ id, name }) => ({
          label: renderLabel(name),
          id,
          value: name,
          fieldName: "alarm_rule_id",
        })) || []),
      ]}
      onChange={(value) => {
        if (!value) {
          onSearchChange({
            value: "",
            fieldName: "",
          });
        }
      }}
      onSearch={(value) => {
        setSearchValue(value);
      }}
      onSelect={(
        _,
        option: {
          id?: string;
          fieldName: string;
          value: string;
        },
      ) => {
        onSearchChange(
          option
            ? {
                fieldName: option.fieldName,
                value: option.id || option.value,
              }
            : {
                value: "",
                fieldName: "",
              },
        );
      }}
      filterOption={(inputValue, option) =>
        !!option?.value.toUpperCase().includes(inputValue.toUpperCase())
      }
    />
  );
};

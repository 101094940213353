import useSWR from "swr";
import { useMemo } from "react";
import { getGroup } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";

export const useHasNextPerson = ({
  alertedGroupId,
  currentResponsibleUserEmail,
}: {
  alertedGroupId?: string;
  currentResponsibleUserEmail?: string;
}) => {
  const handleAPIError = useHandleApiError();

  const {
    data: responsibleMembers = [],
    isLoading: isLoadingMembers,
    error: errorLoadingMembers,
  } = useSWR(["alertedGroup", alertedGroupId], () => {
    return alertedGroupId
      ? getGroup(alertedGroupId).then((group) => group.members)
      : [];
  });

  const currentResponsiblePersonIndex = responsibleMembers.findIndex(
    (person) => {
      return person.properate_user_email === currentResponsibleUserEmail;
    },
  );

  if (errorLoadingMembers) {
    handleAPIError(errorLoadingMembers);
  }

  return useMemo(
    () => ({
      hasNextPerson:
        responsibleMembers.length > 1 &&
        currentResponsiblePersonIndex < responsibleMembers.length - 1,
      isLoading: isLoadingMembers,
    }),
    [currentResponsiblePersonIndex, responsibleMembers, isLoadingMembers],
  );
};

export enum IncidentFormFields {
  AlarmId = "AlarmId",
  DeviationId = "DeviationId",
  IncidentEventVisible = "IncidentEventVisible",
}

export const getIncidentFormFields = ({
  alarmId,
  deviationId,
}: {
  alarmId?: string;
  deviationId?: number;
}) => ({
  [IncidentFormFields.AlarmId]: {
    defaultValue: alarmId || "",
  },
  [IncidentFormFields.DeviationId]: {
    defaultValue: deviationId || "",
  },
  [IncidentFormFields.IncidentEventVisible]: {
    defaultValue: true,
  },
});

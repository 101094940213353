import dayjs from "@properate/dayjs";
import { useTheme } from "styled-components";
import {
  SimpleGraphHighlights,
  SimpleGraphHighlightTimeSpan,
} from "@/features/analysis";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { AlarmGraphFormFields } from "@/pages/alarms/details/components/AlarmTimeseriesGraph";
import { IncidentFormFields } from "@/pages/alarmSystem/incident/utils";
import { useAlarmDeviations } from "./useCogniteAlarmEvents";
import { useAlarmDetailsTimeSpan } from "./useAlarmDetailsTimeSpan";

export function useGraphHighlightsForEvents(): SimpleGraphHighlights {
  const theme = useTheme();

  const [alarmId] = useFormValue<string | undefined>(
    IncidentFormFields.AlarmId,
  );
  const [deviationId] = useFormValue<number | undefined>(
    IncidentFormFields.DeviationId,
  );
  const { deviations } = useAlarmDeviations(alarmId);
  const alarmDetailsTimeSpan = useAlarmDetailsTimeSpan();
  const [visible, setVisible] = useFormValue<boolean>(
    AlarmGraphFormFields.EventsVisible,
  );
  const [visibleIncidentDeviation, setVisibleIncidentDeviation] =
    useFormValue<boolean>(IncidentFormFields.IncidentEventVisible);

  function mergeOverlap(
    timeSpans: SimpleGraphHighlightTimeSpan[],
  ): SimpleGraphHighlightTimeSpan[] {
    // Sort intervals based on start values
    timeSpans.sort((a, b) => a.timeSpan[0] - b.timeSpan[0]);

    const res = [];
    if (timeSpans.length > 0) {
      res.push(timeSpans[0]);
    }

    for (let i = 1; i < timeSpans.length; i++) {
      const last = res[res.length - 1];
      const curr = timeSpans[i];

      // If current overlaps with the last
      // merged, merge them
      if (curr.timeSpan[0] <= last.timeSpan[1]) {
        last.timeSpan[1] = Math.max(last.timeSpan[1], curr.timeSpan[1]);
      } else {
        // Add current to the result
        res.push(curr);
      }
    }

    return res;
  }

  function epochOrFallback(date: Date | undefined | number, fallback: number) {
    if (date === undefined) {
      return fallback;
    }
    return dayjs(date).valueOf();
  }

  const timespans: SimpleGraphHighlightTimeSpan[] = (deviations ?? []).map(
    (deviation) => ({
      timeSpan: [
        epochOrFallback(deviation.startTime, alarmDetailsTimeSpan[0]),
        epochOrFallback(deviation.endTime, alarmDetailsTimeSpan[1]),
      ],
      isDeviationHighlight: deviation.id === deviationId,
      id: deviation.id,
      ...(deviation.id === deviationId && {
        color: theme.highlightBg,
      }),
    }),
  );

  return {
    color: "#D9D9D9",
    opacity: "0.25",
    timeSpans: mergeOverlap(timespans),
    visible,
    toggleVisible: () => setVisible(!visible),
    visibleIncidentDeviation,
    toggleVisibleIncidentDeviation: () =>
      setVisibleIncidentDeviation(!visibleIncidentDeviation),
  };
}

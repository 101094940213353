import useSWRMutation from "swr/mutation";
import { RcFile } from "antd/es/upload";
import Compressor from "compressorjs";
import { collection, doc, updateDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { message } from "antd";
import { useCallback } from "react";
import { useTranslations } from "@properate/translations";
import axios from "axios";
import { FileUploadResponse } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { FloorPlan } from "../types";
import { useFloorPlan } from "../FloorPlanContext";

export function useFloorPlanBackgroundUpdate() {
  const t = useTranslations();
  const floorPlan = useFloorPlan();
  const building = useCurrentBuilding();
  const { optimizeImage } = useImageOptimizer();
  const { client: cogniteClient } = useCogniteClient();

  type Extra = {
    arg: {
      file: RcFile;
    };
  };

  return useSWRMutation(
    [floorPlan.snapshotId, "floor-plan-background"],
    async ([snapshotId], extra: Extra) => {
      const { width, height } = await getImageDimensions(extra.arg.file);
      const optimizedFile = await optimizeImage(extra.arg.file);

      const upload = (await cogniteClient.files.upload({
        name: extra.arg.file.name,
        assetIds: [building.id],
        dataSetId: building.dataSetId,
        mimeType: extra.arg.file.type,
        source: "properate",
        labels: [
          {
            externalId: "internal_schema_background",
          },
        ],
        metadata: {
          width: String(width),
          height: String(height),
        },
      })) as FileUploadResponse;

      await axios.put(upload.uploadUrl, optimizedFile);

      await updateDoc(
        doc(collection(browserFirestore, "floor-plans"), snapshotId),
        {
          background: {
            cogniteFileId: upload.id,
            mimeType: extra.arg.file.type,
            width,
            height,
          },
        } as Pick<FloorPlan, "background">,
      );

      // The old solution never deleted the file from Cognite after replacing it.
      // We're keeping the behaviour here, and address this issue in a scheduled job.
    },
    {
      onSuccess() {
        message.success(t("floor-plan-v2.messages.image-updated"));
      },
      onError() {
        message.error(t("floor-plan-v2.messages.image-updated-failed"));
      },
    },
  );
}

export function getImageDimensions(file: RcFile) {
  type Size = {
    width: number;
    height: number;
  };

  return new Promise<Size>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
}

// todo: convert image to avif
export function useImageOptimizer() {
  const optimizeImage = useCallback(async (userFile: RcFile) => {
    return new Promise((resolve) => {
      new Compressor(userFile, {
        quality: 0.8,
        retainExif: false,
        mimeType: "image/webp",
        success(compressedFile) {
          resolve(compressedFile);
        },
        error() {
          resolve(userFile);
        },
      });
    });
  }, []);

  return {
    optimizeImage,
  };
}
